import React from 'react';
import Linkify from 'linkifyjs/react';
import { ClinicWidgetRequestTypeResponse, ClinicWidgetSettingResponse } from 'shared/types';
import { GATrack } from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Card } from '@televet/kibble-ui/build/components/Card';
import { Flex, Circle, Box } from '@televet/kibble-ui/build/chakra';
import { WidgetRequestTypeLabels } from '@televet/shared-types/widgetRequest/WidgetRequestTypeLabels';
import { getRequestTypeIconName } from 'shared/utils/getRequestTypeIconName';
import { WidgetRequestType } from 'shared/types/graphql';

interface IWidgetPreviewProps {
  widgetSetting: ClinicWidgetSettingResponse;
  isMobile: boolean;
  onCloseClick: () => void;
  onRequestTypeClick: (type: ClinicWidgetRequestTypeResponse | null) => void;
}

const WidgetPreview = ({
  widgetSetting,
  isMobile,
  onCloseClick,
  onRequestTypeClick,
}: IWidgetPreviewProps): JSX.Element => {
  const { welcomeMessage, clinicWidgetRequestTypes, clinic } = widgetSetting;

  return (
    <Flex
      className="WidgetPreview__Container"
      w="310px"
      flexDir="column"
      align="flex-end"
      mr={isMobile ? 3 : 'unset'}
      mb={isMobile ? '92px' : '88px'}
      gap={3}
      data-testid="widget-preview-container"
    >
      <Button
        className="WidgetPreview__CloseButton"
        onClick={onCloseClick}
        borderRadius="full"
        h="34px"
        w="34px"
        size="sm"
        variant="secondarySubtle"
        bgColor="background.subtle"
      >
        <Icon name="close" size="md" />
      </Button>

      <Card className="WidgetPreview__WelcomeMessage" boxShadow="base">
        <Heading size="xs">Welcome to {clinic[0].name}!</Heading>

        {welcomeMessage && (
          <Linkify>
            <Text size="sm" mt={2} as="p">
              {welcomeMessage}
            </Text>
          </Linkify>
        )}
      </Card>

      {clinicWidgetRequestTypes?.slice(0, 2).map((clinicWidgetRequestType) => (
        <Button
          className="WidgetPreview__RequestButton"
          key={clinicWidgetRequestType.id}
          onClick={(): void => onRequestTypeClick(clinicWidgetRequestType)}
          variant="secondarySubtle"
          boxShadow="base"
          gap={2}
          data-testid="request-type-button"
        >
          <Box pos="relative" boxSize={5}>
            <Icon name={getRequestTypeIconName(clinicWidgetRequestType?.requestType || '')} size="md" />
            {clinicWidgetRequestType?.requestType === WidgetRequestType.Teletriage && (
              <Circle
                bgColor="text.success"
                size={2}
                borderWidth={1}
                borderColor="border.strongContrast"
                pos="absolute"
                top={0}
                right={0}
              />
            )}
          </Box>
          <Text size="sm">
            {clinicWidgetRequestType?.displayName || WidgetRequestTypeLabels[clinicWidgetRequestType.requestType]}
          </Text>
          <Icon name="chevronRight" size="sm" />
        </Button>
      ))}

      {clinicWidgetRequestTypes && clinicWidgetRequestTypes.length > 2 && (
        <Button
          className="WidgetPreview__RequestButton--Other"
          onClick={(): void => {
            GATrack(GA4Events.OTHER_OPTIONS_CLICK, { clinicId: clinic[0].id });
            onRequestTypeClick(null);
          }}
          variant="secondarySubtle"
          boxShadow="base"
          gap={2}
        >
          <Circle bgColor="background.contrast" sx={{ svg: { color: 'text.onContrast' } }}>
            <Icon name="dotsHorizontal" size="md" />
          </Circle>
          <Text size="sm">Other Options</Text>
          <Icon name="chevronRight" size="sm" />
        </Button>
      )}
    </Flex>
  );
};

export default WidgetPreview;

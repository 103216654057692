import { create } from 'zustand';

export interface InitializeOptions {
  clinicId?: string;
  parentUrl?: string;
  isOpen?: boolean;
  isMobile?: boolean;
  isInline?: boolean;
  showPreview?: boolean;
  isPetPortal?: boolean;
}

interface State {
  clinicId: string;
  parentUrl: string;
  isOpen: boolean;
  onToggle: (shouldOpen?: boolean) => void;
  isMobile: boolean;
  isInline: boolean;
  showPreview: boolean;
  isPetPortal: boolean;
  initialize: (data: InitializeOptions) => void;
}

export const useWidgetStore = create<State>()((set) => ({
  clinicId: '',
  parentUrl: '',
  isOpen: false,
  onToggle: (shouldOpen?: boolean): void => {
    if (shouldOpen !== undefined) {
      shouldOpen ? set(() => ({ isOpen: true })) : set(() => ({ isOpen: false }));
    } else {
      set((state) => (state.isOpen ? { isOpen: false } : { isOpen: true }));
    }
    set(() => ({ showPreview: false }));
  },
  showPreview: true,
  isMobile: false,
  isInline: false,
  isPetPortal: false,
  initialize: (options: InitializeOptions): void => {
    if (options.clinicId) {
      set(() => ({ clinicId: options.clinicId }));
    }

    if (options.parentUrl) {
      set(() => ({ parentUrl: options.parentUrl }));
    }

    set((state) => ({ isMobile: options.isMobile ? options.isMobile : state.isMobile }));
    set((state) => ({ isInline: options.isInline ? options.isInline : state.isInline }));
    set((state) => ({ isOpen: options.isOpen ? options.isOpen : state.isOpen }));
    set((state) => ({ isPetPortal: options.isPetPortal ? options.isPetPortal : state.isPetPortal }));
    set((state) => ({ showPreview: options.showPreview ? options.showPreview : state.showPreview }));
  },
}));

import React, { useEffect, useState, useMemo, useCallback, useRef, lazy, Suspense } from 'react';
import { KibbleThemeProvider, theme as kibbleTheme } from '@televet/kibble-ui/build/theme';
import { Fade, Box } from '@televet/kibble-ui/build/chakra';
import * as Sentry from '@sentry/react';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import {
  IWindowMessageData,
  IInitializeWidgetPayload,
  ISelectRequestTypePayload,
  ISetIsMobilePayload,
} from 'shared/interfaces/IWindowMessageData';
import { WindowMessageEvent } from 'shared/enums/WindowMessageEvent';
import { GraphQLFetchPolicy } from 'shared/enums/GraphQLFetchPolicy';
import { WidgetRequestType, useGetClinicWidgetSettingLazyQuery } from 'shared/types/graphql';
import usePrevious from 'shared/hooks/usePrevious';
import Launcher from './Launcher';
import WidgetPreview from './WidgetPreview';
import { ClinicWidgetRequestTypeResponse } from 'shared/types';
import { DirectBookingStep } from 'shared/enums/DirectBookingStep';
import { env } from 'env';
import { PrescriptionRefillStep } from 'shared/enums/PrescriptionRefillStep';
import useGA, { GATrack } from 'shared/hooks/useGA';
import { WidgetRequestTypeEventPrefix } from 'shared/enums/WidgetRequestTypeEventPrefix';
import { GA4Events } from 'shared/enums/GA4Events';
import { commitInfo } from 'commitInfo';
import useWidgetTheme from 'theme/useWidgetTheme';
import { useWidgetStore } from 'shared/hooks/useWidgetStore';
import { useShallow } from 'zustand/react/shallow';

const Widget = lazy(() => import('./Widget'));
const WIDGET_CONTAINER_HEIGHT = 632;
declare global {
  interface Window {
    parentIFrame: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

export const appInfo = { appVersion: parseInt(commitInfo.commitNumber) / 100, ...commitInfo };

const Application = (): JSX.Element | null => {
  const applicationContainerRef = useRef<HTMLDivElement | null>(null);

  const [clinicId, parentUrl, isInline, isMobile, isOpen, showPreview, onToggle, initialize] = useWidgetStore(
    useShallow((state) => [
      state.clinicId,
      state.parentUrl,
      state.isInline,
      state.isMobile,
      state.isOpen,
      state.showPreview,
      state.onToggle,
      state.initialize,
    ]),
  );

  const [clinicWidgetRequestType, setClinicWidgetRequestType] = useState<ClinicWidgetRequestTypeResponse | null>(null);
  const [directBookingStep, setDirectBookingStep] = useState<DirectBookingStep>(DirectBookingStep.ContactInfo);
  const [prescriptionRefillStep, setPrescriptionRefillStep] = useState<PrescriptionRefillStep>(
    PrescriptionRefillStep.ContactInfo,
  );
  const [defaultClientPhone, setDefaultClientPhone] = useState<string>();
  const [defaultClientEmail, setDefaultClientEmail] = useState<string>();

  useGA({ clinicId });

  const [
    getClinicWidgetSettingData,
    { data: clinicWidgetSettingData, loading: isClinicWidgetSettingLoading, error: clinicWidgetSettingError },
  ] = useGetClinicWidgetSettingLazyQuery({
    fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
  });

  const integrations = useMemo(
    () => clinicWidgetSettingData?.findFirstClinicWidgetSetting?.clinic?.[0]?.integrations || [],
    [clinicWidgetSettingData?.findFirstClinicWidgetSetting?.clinic],
  );

  const widgetSetting = useMemo(() => {
    const clinicWidgetSetting = Object.assign({}, clinicWidgetSettingData?.findFirstClinicWidgetSetting);
    const requestTypes = clinicWidgetSetting?.clinicWidgetRequestTypes;

    if (clinicWidgetSetting && requestTypes?.length) {
      // TODO: when putting into pet portal change filter to not exclude InboundSms
      clinicWidgetSetting.clinicWidgetRequestTypes = requestTypes
        .filter((type) => type.isActive && !type.isDeleted && type.requestType !== WidgetRequestType.InboundSms)
        .sort((a, b) => a.order - b.order);
    }

    return clinicWidgetSetting;
  }, [clinicWidgetSettingData]);

  const theme = useWidgetTheme({
    primaryColor: widgetSetting?.primaryColor || kibbleTheme.colors.primary['500'],
    secondaryColor: widgetSetting?.secondaryColor || kibbleTheme.colors.neutral['900'],
  });

  const resizeIFrame = useCallback((): void => {
    if ('parentIFrame' in window) {
      window.parentIFrame.getPageInfo((info: { clientWidth: number }) => {
        const rect = applicationContainerRef?.current?.getBoundingClientRect();

        window.parentIFrame.size(
          isOpen && isMobile ? WIDGET_CONTAINER_HEIGHT : rect?.height,
          isOpen && isMobile ? info.clientWidth : rect?.width,
        );
      });
    }
  }, [isOpen, isMobile]);

  useEffect(() => {
    if (widgetSetting) {
      resizeIFrame();
    }
  }, [widgetSetting, isOpen, isMobile, resizeIFrame]);

  useEffect(() => {
    if (clinicId) {
      try {
        getClinicWidgetSettingData({
          variables: {
            where: {
              clinic: {
                some: {
                  id: {
                    equals: clinicId,
                  },
                },
              },
            },
          },
        });
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    }
  }, [clinicId, getClinicWidgetSettingData]);

  const selectRequestType = useCallback(
    (type: ClinicWidgetRequestTypeResponse | null): void => {
      if (type) {
        GATrack(`${WidgetRequestTypeEventPrefix[type.requestType]}_${GA4Events.START}`, { clinicId });
      }

      if (type?.requestType === WidgetRequestType.DirectBooking) {
        setDirectBookingStep(DirectBookingStep.ContactInfo);
      }

      if (type?.requestType === WidgetRequestType.AdvancedRequestRxRefill) {
        setPrescriptionRefillStep(PrescriptionRefillStep.ContactInfo);
      }

      setClinicWidgetRequestType(type);
      onToggle(true);
    },
    [clinicId, onToggle],
  );

  const [selectedRequestType, setSelectedRequestType] = useState<WidgetRequestType | null>(null);
  const previousSelectedRequestType = usePrevious(selectedRequestType);

  useEffect(() => {
    if (
      selectedRequestType &&
      (previousSelectedRequestType === null || previousSelectedRequestType === selectedRequestType) &&
      widgetSetting.clinicWidgetRequestTypes?.length
    ) {
      const selectedClinicWidgetRequestType = widgetSetting.clinicWidgetRequestTypes?.find(
        (r) => r.requestType === selectedRequestType,
      );

      if (selectedClinicWidgetRequestType) {
        selectRequestType(selectedClinicWidgetRequestType);
        setSelectedRequestType(null);
      }
    }
  }, [selectedRequestType, previousSelectedRequestType, widgetSetting, selectRequestType]);

  const handleMessage = useCallback(
    (e: MessageEvent): void => {
      const data: IWindowMessageData<IInitializeWidgetPayload & ISelectRequestTypePayload & ISetIsMobilePayload> =
        e.data;

      switch (e.data.event) {
        case WindowMessageEvent.InitializeWidget:
          initialize({
            clinicId: data.payload.clinicId,
            parentUrl: data.payload.url,
            isMobile: data.payload.isMobile,
            isInline: data.payload.isInline,
            isPetPortal: data.payload.isPetPortal,
            isOpen: data.payload.isOpen,
            showPreview: data.payload.showPreview,
          });

          break;
        case WindowMessageEvent.OpenWidget:
          onToggle(true);

          break;
        case WindowMessageEvent.CloseWidget:
          onToggle(false);

          break;
        case WindowMessageEvent.ToggleWidget:
          onToggle();

          break;
        case WindowMessageEvent.SelectRequestType:
          if (!data.payload.requestType) {
            return;
          }

          setSelectedRequestType(data.payload.requestType);

          break;
        case WindowMessageEvent.SetIsMobile:
          if (data.payload.hasOwnProperty('isMobile')) {
            console.log('isMobile: ' + data.payload.isMobile);
            initialize({ isMobile: data.payload.isMobile });
          }
          break;
        default:
          return;
      }
    },
    [initialize, onToggle],
  );

  useEffect(() => {
    window.parent.postMessage(
      {
        event: WindowMessageEvent.OnWidgetReady,
      },
      '*', // Evaluate whether this target origin is appropriate if we send sensitive data in the future
    );
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e: MessageEvent) => {
      handleMessage(e);
    });

    return (): void => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    if (!widgetSetting || !Object.keys(widgetSetting).length) return;

    const requestTypes = widgetSetting.clinicWidgetRequestTypes?.map(({ displayName, requestType, order }) => ({
      displayName,
      requestType,
      order,
    }));

    GATrack(GA4Events.IMPRESSION, {
      clinicId,
      url: parentUrl,
      requestTypes: requestTypes.map((rt) => rt.requestType).join(', '),
      isInline,
    });

    window.parent.postMessage(
      {
        event: WindowMessageEvent.OnWidgetInitialized,
        payload: { requestTypes },
      },
      '*', // Evaluate whether this target origin is appropriate if we send sensitive data in the future
    );
  }, [widgetSetting, parentUrl, clinicId, isInline]);

  const isOnHostedPreviewPage = useMemo(() => {
    if (!parentUrl) return false;

    return ['connect.televet.com', 'connect-demo.televet.com', 'connect-develop.televet.com'].includes(
      new URL(parentUrl).hostname,
    );
  }, [parentUrl]);

  const isInQaLane = useMemo(() => {
    if (!parentUrl) return false;

    // Matches qa and test lanes for widget and pet portal
    const qaUrlRegex = /(widget|pets)\.(qa(\d+)|test)\.dev\.otto\.vet/;

    return qaUrlRegex.test(new URL(parentUrl).hostname);
  }, [parentUrl]);

  const isOnHostedPetPortal = useMemo(() => {
    if (!parentUrl) return false;

    return ['pet.otto.vet', 'care-demo.televet.com'].includes(new URL(parentUrl).hostname);
  }, [parentUrl]);

  const isUrlWhitelisted = useMemo(() => {
    if (!widgetSetting?.whiteLabelUrls || !parentUrl) return false;

    const isRunningLocally = env.NODE_ENV !== 'production' && new URL(parentUrl).hostname === 'localhost';

    if (isRunningLocally || isOnHostedPreviewPage || isInQaLane || isOnHostedPetPortal) {
      return true;
    }

    const whitelistUrls: string[] = widgetSetting.whiteLabelUrls;

    return whitelistUrls.some(
      (url) =>
        url === '*' ||
        new URL(url).hostname.replace(/^(www\.)/i, '') === new URL(parentUrl).hostname.replace(/^(www\.)/i, ''),
    );
  }, [widgetSetting.whiteLabelUrls, parentUrl, isOnHostedPreviewPage, isInQaLane, isOnHostedPetPortal]);

  useEffect(() => resizeIFrame(), [showPreview]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showPreview && isOpen) {
      onToggle(true);
    }
  }, [isOpen, onToggle, showPreview]);

  if (isClinicWidgetSettingLoading || clinicWidgetSettingError || !widgetSetting?.isEnabled || !isUrlWhitelisted) {
    return null;
  }

  return (
    <Box
      className="Widget"
      display="inline-block"
      ref={applicationContainerRef}
      p={isMobile || isInline ? 0 : 3}
      w={(isMobile || isInline) && isOpen ? '100%' : 'auto'}
      minW="92px"
      maxW={(isMobile || isInline) && isOpen ? '100%' : 'unset'}
      h={isInline ? '100vh' : 'auto'}
      minH="92px"
      maxH={isMobile || isInline ? '100%' : isInline ? '100vh' : 'unset'}
      data-iframe-height
      data-iframe-width
    >
      <KibbleThemeProvider theme={theme}>
        {isOpen && (
          // null fallback because it loads so quickly that an intermediate render looks bad
          <Suspense fallback={null}>
            <Widget
              widgetSetting={widgetSetting}
              clinicWidgetRequestType={clinicWidgetRequestType}
              onRequestTypeSelect={selectRequestType}
              setDirectBookingStep={setDirectBookingStep}
              directBookingStep={directBookingStep}
              setPrescriptionRefillStep={setPrescriptionRefillStep}
              prescriptionRefillStep={prescriptionRefillStep}
              onResizeIFrame={resizeIFrame}
              integrations={integrations}
              defaultClientPhone={defaultClientPhone}
              defaultClientEmail={defaultClientEmail}
              setDefaultClientPhone={setDefaultClientPhone}
              setDefaultClientEmail={setDefaultClientEmail}
            />
          </Suspense>
        )}

        {showPreview && (
          <Fade
            className="WidgetPreview__FadeContainer"
            in={showPreview}
            transition={{ exit: { duration: 0.5 }, enter: { duration: 0.5 } }}
          >
            <WidgetPreview
              data-iframe-height
              data-iframe-width
              widgetSetting={widgetSetting}
              isMobile={isMobile}
              onRequestTypeClick={selectRequestType}
              onCloseClick={(): void => {
                GATrack(GA4Events.DISMISS_X_BUTTON, { clinicId });
                onToggle(false);
              }}
            />
          </Fade>
        )}

        {!(isMobile && isOpen) && !isInline && (
          <Fade className="WidgetPreview__FadeContainer" in={true} transition={{ enter: { duration: 0.5 } }}>
            <Launcher
              onToggleWidget={(): void => {
                if (isOpen) {
                  GATrack(GA4Events.DIMISS_V_ICON, { clinicId });
                } else {
                  GATrack(GA4Events.CHAT_BUBBLE_CLICK, { clinicId, isPreview: showPreview });
                }
                onToggle();
              }}
            />
          </Fade>
        )}
      </KibbleThemeProvider>
    </Box>
  );
};

export default Application;

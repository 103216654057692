import React from 'react';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Flex } from '@televet/kibble-ui/build/chakra';
import { useWidgetStore } from 'shared/hooks/useWidgetStore';

interface ILauncherProps {
  onToggleWidget: () => void;
}

const Launcher = ({ onToggleWidget }: ILauncherProps): JSX.Element => {
  const isOpen = useWidgetStore((state) => state.isOpen);

  return (
    <Flex className="WidgetPreview__LaunchButton" pos="absolute" bottom={3} right={3}>
      <Button
        onClick={onToggleWidget}
        variant="primary"
        borderRadius="full"
        w="68px"
        h="68px"
        data-testid="widget-launcher-button"
      >
        <Icon name={isOpen ? 'chevronDown' : 'chatBubble'} color="text.default" size="2xl" />
      </Button>
    </Flex>
  );
};

export default Launcher;
